@use '@angular/material' as mat;
@import url(https://fonts.googleapis.com/css?family=Roboto+Slab|Open+Sans:400italic,700italic,400,700);
@import url(https://fonts.googleapis.com/css?family=Lato|Open+Sans:400italic,700italic,400,700);


@import './font-awesome.css';
@import 'styles-variables';
@import './themes/vars.scss';
@import './eduardo_media.scss';
// @import 'styles-reset';

// @import '../node_modules/@angular/material/_theming';

// @include mat.core();
//
// @import './themes/default-theme.scss';
// @import './themes/light-theme.scss';
// @import './themes/black-theme.scss';
// @import './themes/basic-count-theme.scss';
// @import './themes/top-theme.scss';
//
// @import 'styles-reset.scss-theme';
// @import 'app/app.component.scss-theme';
// @import '@angular/material/prebuilt-themes/deeppurple-amber.css';
//
// @import '@angular/material/prebuilt-themes/indigo-pink.css';
//
// @mixin custom-components-theme($theme) {
// @include anms-styles-reset-theme($theme);
// @include anms-app-component-theme($theme);
// }
//
//
// $my-primary: mat.define-palette(mat.$indigo-palette, 500);
// $my-accent: mat.define-palette(mat.$pink-palette, A200, A100, A400);
// $my-warn: mat.define-palette(mat.$red-palette);
//
// $my-theme: mat.define-light-theme((
//  color: (
//    primary: $my-primary,
//    accent: $my-accent,
//    warn: $my-warn,
//  )
// ));
//
// $my-app-primary:  mat.get-color-from-palette($my-app-primary, default);
// $my-app-accent:  mat.get-color-from-palette($my-app-accent, default);
// $my-app-warn:  mat.get-color-from-palette($my-app-warn, default);
//
// @include mat.all-component-themes($my-theme);

@include mat.core();

// $my-app-primary: mat.define-palette(mat.$indigo-palette,900);
// $my-app-primary: mat.define-palette(mat.$blue-grey-palette,600);
$my-app-primary: mat.define-palette(mat.$blue-grey-palette,900);



// $my-app-accent: mat.define-palette(mat.$pink-palette, A200, A100, A400);
// $my-app-accent: mat.define-palette(mat.$teal-palette, 900);
// $my-app-accent: mat.define-palette(mat.$teal-palette, 600);
// $my-app-warn: mat.define-palette(mat.$deep-orange-palette,700);


// $my-app-warn: mat.define-palette(mat.$red-palette);
$my-app-warn: mat.define-palette(mat.$red-palette,A400);
// $my-app-warn: mat.define-palette(mat.$brown-palette,600);


// $my-app-blue-grey: mat.define-palette(mat.$blue-grey-palette,600);
$my-app-blue-grey: mat.define-palette(mat.$blue-grey-palette,300);
$my-app-grey: mat.define-palette(mat.$grey-palette,800);

$teal700: #00796b;
$deeporange700: #e64a19;

$my-app-accent: mat.define-palette(mat.$teal-palette, 700);



$my-app-theme: mat.define-light-theme(
  (
    color: (
      primary: $my-app-primary,
      accent: $my-app-accent,
      warn: $my-app-warn,
      blue_grey: $my-app-blue-grey,
      grey800: $my-app-grey
    ),
  )
);

@include mat.all-component-themes($my-app-theme);

.blue-grey-primary {
  background: #546e7a;
  color: white;
}
.grey800-primary {
  background:#424242;
  color: white;
}
.jcircle {
  height: 6px;
  width: 6px;
  background-color: black;
  border-radius: 3px;
}

// .default-theme {
//    @include mat.all-component-themes($anms-top-theme);
// }
//
// .light-theme {
//   @include mat.all-component-themes($anms-top-theme);
// }
.black-border {
   border: 2px solid black !important;
}

.blue-bg {
   background:blue !important;
}
.red-border {
   border: 2px solid red !important;
}
.green-border {
   border: 2px solid green !important;
}
.blue-border {
   border: 2px solid blue !important;
}
.white-border {
  border: 2px solid white !important;
}

.font {
  font-size: 24px !important;
  @include media('>=phone', '<tablet') {
       font-size: 20px !important;
     }
}


.setting-font-size {
    font-size: 20px !important;
}
.red-bg {
  background: red !important;
  color: black !important;
}
/*
.force-border {
  border:0.5rem outset lightblue !important;
}
*/
.form-font-size {
  font-size: 23px;
}



.rule-prediction-column {
  background: $teal700;
  color:black;
  text-align: center !important;
}

.rule-book-column {
  background: $deeporange700;
  color:black;
  text-align: center !important;

}

.accent-bg {
  color: black !important;
  background: map_get($my-app-accent,900) !important;
}
.fail-certification-border {
  border: 20px solid red !important;

}
.pass-certification-border {
  border: 20px solid darkblue !important;

}

.warn-bg {
  color:black !important;
  background: map_get($my-app-warn,900) !important;
}
.losses-text {
  width:600px;
  height:40px;
  font-size:16px;
}
.mobile-bottom-toolbar {
  @extend .warn-bg;
  color:black !important;
}
.primary-bg {
  background: map_get($my-app-primary,900) !important;
  color:white !important;
}
.losses-text-primary {
  @extend .losses-text;
  @extend .primary-bg;
}
.losses-text-warn {
  @extend .losses-text;
  @extend .warn-bg;
}
.primary-ang-combo {
  @extend .primary-bg;
  color: white !important;
}
.warn-ang-combo {
  @extend .warn-bg;
  color: black !important;
}
.accent-ang-combo {
  @extend .accent-bg;
  color: black;
}
.mat-tooltip {
  font-size: 20px !important;
  background: map_get($my-app-primary,900) !important;
  foreground: black;
}
$med_font_size: 1.0em !important;
.mat-tab {
 color: map_get($my-app-primary,900) !important;
}
// .mat-cell {
//   color: mat_get($my-app-primary,900) !important;
//   font-size: $med_font_size;
// }
// .mat-header-cell {
//   color: mat_get($my-app-primary,900) !important;
//   font-size: $med_font_size;
// }
// .font-primary-color {
//   color: mat_get($my-app-primary,900) !important;
// }

// .mat-tab-label {
//   color: mat_get($my-app-primary,900) !important;
//   font-size: $med_font_size;
//  }

.small-font {
  font-size:15px !important;
  @include media('>=phone', '<tablet') {
    font-size:10px !important;
   }
}
.medium-font {
  font-size:20px !important;
  @include media('>=phone', '<tablet') {
    font-size:15px !important;
   }
}
// .primary-font-color {
//   color: mat_get($my-app-primary,900) !important;
// }

// .warn-background {
//   background-color:  mat_get($my-app-warn,900) !important;
//   color: black;
// }


.mobile-side-bg {
  background: #212121 !important;
  color: white !important;
}
.rules-buttons {
  width:300px;
  height:50px;
}
.foreground-white {
   /*foreground: white;*/
   color: white;
}
.margin-left-c-buttons {
  margin-left:8px !important;
}
.font-1-6em {
 font-size: 1.6em;
}
.yellow-font {
  color: #c88719
}
.black-font {
  color: black
}
.scale2 {
 transform: scale(2.0) !important;
}
.scale1a5 {
 transform: scale(1.5) !important;
}
.scale1a3 {
 transform: scale(1.3) !important;
}
.scale1p5 {
 transform: scale(1.5) !important;
}
.scale1p2 {
 transform: scale(1.2) !important;
}
.scale1p3 {
 transform: scale(1.3) !important;
}
.scale2 {
 transform: scale(2) !important;
}
.scale3 {
 transform: scale(3) !important;
}
.scale4 {
 transform: scale(4) !important;
}
.scale-p5 {
 transform: scale(0.5) !important;
}
.scale-p6 {
 transform: scale(0.6) !important;
}
.toolbar-scale {
  @include media('>=phone', '<tablet') {
      transform: scale(0.9) !important;
   }
}
.scale-p7 {
 transform: scale(0.7) !important;
}
.scale-p8 {
 transform: scale(0.8) !important;
}
.scale-p9 {
 transform: scale(0.8) !important;
}
.cards-header {
  color: black;
  margin-left:50px
}
.cards-bground {
  background-color: #1C2833;
}

.cards-spacing {
  margin-left:5px;
}

.card-list-width {
  max-width:700px;
  @include media('>=phone', '<tablet') {
   max-width:450px;
  }
}


.sixty-pcnt {
  max-width:60%
}

.width100px {
  width:100px;
}
.width150px {
  width:150px;
}
.primary-dark-blue {
  background: #000066;
  color: white;
}
.flex-column {
  display: flex;
  flex-direction: column
}
.margin-right-menu {
  margin-right: 100px;
}
.flex-row {
  display: flex;
  flex-direction: row;
}
.flex-row-space-between {
   @extend .flex-row;
  justify-content: space-between;
}
.flex-column-space-between {
  @extend .flex-column;
  justify-content: space-between;
}
.flex-column-reverse-space-between {
  @extend .flex-column-reverse;
  justify-content: space-between;
}
.top-bottom-flex-column{
  @extend .flex-column-reverse;
  justify-content: space-between;
  height:100%;
}
.align-start {
  align-self:  flex-start;
}
.align-end {
  align-self:  flex-end;
}

.very_light_blue {
  background: #006699 !important;
  color:white !important;
}



.flex-item-center {
  display: flex;
  justify-content:space-center;
}
.flex-space-between {
  display: flex;
  justify-content: space-between;
}
.hcount-font {
  color:  mat.get-color-from-palette($my-app-primary, darker) !important;
  /*color: darkblue;*/
  font-size: 1.3em;
}
@mixin badge_others($colour, $scale) {
  color: $colour !important;
/*  transform: scale($scale) !important;*/
}
.green-light{
  /*background: #f6faf9 !important;*/
  background: green !important;
}
.yellow-light{
  /*background: #faf9f6 !important;*/
  background: yellow !important;
}
.red-light{
  background: #df262c !important;
}

 .font-18px {
   font-size:18px !important;
 }
.margin0 {
  margin: 0px !important;
}

.slick-wrapper{
    width: 1000px;
    margin: 20px auto;
}

.slick-prev:before, .slick-next:before {
    color: black!important;
}


  .use-all-space {
    width:100%;
    height:100%
  }

  .good-dark-blue-background {
    background: #0f1724;
    color: white !important;
    background: rgb(30, 42, 64);
    color:#92abcf !important;
  }

  .aws-background {
    /* background:#00464f;*/
    background:#000066;
  }
  .aws-margin-top {
    margin-top:100px;
    @include media('>=phone', '<tablet') {
     margin-top:0px;
    }
  }
  .mat-card-desktop-text-wrap {
    inline-size: 380px !important;
    overflow-wrap: break-word !important;
  }
  .mat-card-mobile-text-wrap {
    inline-size: 180px !important;
    overflow-wrap: break-word !important;
  }
  .mat-card-button-80p {
    width:80%;
  }

  .aws-mat-card {
    margin-top:20px;
    font-size:30px;
    /* height:100%;*/
    height:400px;
    width:400px;
    margin-right:20px;
    @extend .mat-card-desktop-text-wrap;
    @include media('>=phone', '<tablet') {
      font-size:20px;
    }
  }

  .margin-top-15-pcnt {
    margin-top:15%
  }

  .aws-container-height {
    height: 60%;
    @include media('>=phone', '<tablet') {
      height:450px;
    }
  }

  .aws-red-color{
    color: red;
    font-size:40px;
    @include media('>=phone', '<tablet') {
      width:200px;
      font-size:20px;
    }
  }

  .flip-card-base {
    background-color: transparent;
    /* perspective: 1000px;*/
    perspective: 500px;


  }
  .flip-card {
      @extend .flip-card-base;
      /* width: 400px;
       height: 600px;
       */
      width: 950px;
      height: 900px;
      @include media('>=phone', '<tablet') {
        width: 400px;
        height: 600px;
      }
      @include media('>=tablet', '<desktop') {
        width: 760px;
        height: 800px;

      }
  }
  .font-45 {
    font-size:45px !important;
  }
  .center-pos {
    margin-left:75px;
    margin-right:75px;
    font-size: 35px !important;
    /* font-weight: 550;*/
    @include media('>=phone', '<tablet') {
      margin-left:0px;
      margin-right:0px;
      font-size: 20px !important;
      /* font-weight: 550;*/
     }
  }

  .center-all {
    display: flex;
    height:100%;
    width:100%;
    justify-content: center ;
    @include media('>=phone', '<tablet') {
         justify-content: flex-start;
        }
  }
  .big-font {
    font-size: 25px !important;
    @include media('>=phone', '<tablet') {
      	font-size: 15px !important;
    }
  }
  .max-width {
    max-width:100%;
  }
  .example-card {
    margin-top:20px;
    max-width: 500px;
    @include media('>=phone', '<tablet') {
      max-width: 300px;

    }
  }

  .border-blue {
    border:1px solid blue !important;
  }
  .border-red {
    border:1px solid red !important;
  }

  .timer-primary-look {
    @extend .flex-row;
    @extend .border-blue;
    @extend .primary-ang-combo;
    max-width:175px;
    max-height:200px;
  }
/*
  .slick-wrapper{
      margin: 20px auto;
  }
  */

  .margin-right-30px {
    margin-right: 30px !important;
  }
  .table-font {
    @include media('>=phone', '<tablet') {
  	font-size: 15px !important;
    }
  }
  .center-pos-1 {
    margin-left:75px;
    margin-right:75px;
    @include media('>=phone', '<tablet') {
      margin-left:0px;
      margin-right:0px;
     }
  }

  .center-20-pcnt {
    margin-left:20%;
    @include media('>=phone', '<tablet') {
      margin-left:0%;
     }
  }
  .relative-100p {
    position: relative;
    width:100%;
    height:100%;
  }
  .dollar-font {
    font-size: 20px !important;
    @include media('>=phone', '<tablet') {
         font-size: 15px !important;
       }
  }
  .text-overflow {
      @include media('>=phone', '<tablet') {
        white-space: normal !important;
        inline-size: 330px !important;
        overflow-wrap: break-word !important;

      }

  }
  .flip-card-inner-base {
    position: relative;
    text-align: center;
    transition: transform 0.6s;
    transition: transform 1s;
    transform-style: preserve-3d;
    box-shadow: 0 4px 8px 0 rgba(0,0,0,0.2);
  }
  .flip-card-inner {
    @extend .flip-card-inner-base;
    width: 100%;
    height: 100%;
  }

  .margin-all-4 {
    margin:4px;
  }
  .player_table_margin {
    margin-right:3px;
  }

  .cardcount-font {
    font-size: 17px !important;
    @include media('>=phone', '<tablet') {
         font-size: 17px !important;

       }
  }


  .card-count-dressing {
    min-width:50px !important;
    min-height:30px !important;
    text-align: center !important;
    @extend .cardcount-font;
    border: 2px dotted white;
    @extend .enter;

  }

  .card-count-dressing-mobile {
    min-width:40px !important;
    min-height:25px !important;
    text-align: center !important;
    font-size: 14px !important;
    border: 2px dotted white;
    @extend .enter;

  }

  .card-count-dressing-modified {
    text-align: center !important;
    @extend .cardcount-font;
    border: 2px dotted white;
    // @extend .primary-bg;
    // @extend .leave-white;
    @extend .enter;

  }


  .margin-left-30p {
    margin-left:30% !important;
    margin-right:30% !important;

    @include media('>=phone', '<tablet') {
      margin-left:0px !important;
      margin-right:0px !important;
    }
    @include media('>=tablet', '<desktop') {
      margin-left:0px !important;
      margin-right:0px !important;

    }
  }
  .margin-side-20 {
    margin-right:20% !important;

    @include media('>=phone', '<tablet') {
      margin-left:0px !important;
      margin-right:0px !important;
    }
    @include media('>=tablet', '<desktop') {
      margin-left:0px !important;
      margin-right:0px !important;

    }
  }
  .margin-side-10 {
    margin-right:10% !important;

    @include media('>=phone', '<tablet') {
      margin-left:0px !important;
      margin-right:0px !important;
    }
    @include media('>=tablet', '<desktop') {
      margin-left:0px !important;
      margin-right:0px !important;

    }
  }




  .full-height-width {
    width:100% !important;
    height:100% !important;
  }
  .margin-bottom-10px {
    margin-bottom:10px !important;
  }
  .margin-bottom-5px {
    margin-bottom:5px !important;
  }
  .margin-all-2 {
    margin:2px;
  }
  .flip-card-large {
    @extend .flip-card-base;
    width: 100%;
    height: 100%;
      z-index:1;
  }


  .null {}
  .center-container {
    @extend .flex-row;
    justify-content: center;
      border: 1px solid black;
  }
  .mobile-toolbar-large-margin {
    margin-right: 35px;
  }

  .mobile-toolbar-large-margin-1 {
    margin-right: 65px;
  }

  .mobile-toolbar-small-margin {
     margin-right: 20px
  }

  /* This container is needed to position the front and back side */

  .flip-card-inner-large {
    @extend .flip-card-inner-base;
    width: 100%;

    height: 100%;
    z-index:1;
  }


  /* Position the front and back side */
  .front {
    position: absolute;
    width: 100%;
    height: 100%;
    -webkit-backface-visibility: hidden; /* Safari */
    backface-visibility: hidden;
    transform: rotateY(0deg);
  }
  .back {
    position: absolute;
    width: 100%;
    height: 100%;
    -webkit-backface-visibility: hidden; /* Safari */
    backface-visibility: hidden;
    transform: rotateY(180deg);

  }
  /* Style the back side */

  .bottom-right-2-left{
    display: flex;
    flex-direction: row-reverse;
    align-self: flex-end;
  }
  .align-start {
    align-self: flex-start;
  }

  .visible {
    visibility: visible;
  }
  .hidden{
    visibility: hidden;
  }
  .white-font {
    z-index:1;
    color:white !important;
  }
  .border-1px-blue {
    border: 1px solid blue;

  }

  .cards-height {
  }

  .justify-center {
    justify-content: center;
  }

  .max-width {
    min-width:100%;
  }
  .max-height {
     height:100%;
  }
  $orange:#ff9b54;
  .orange {
    background: $orange
  }
  .shuffle {
    display: flex;
    margin-left:30px;
  }

  .flex-column {
    display: flex;
    flex-direction: column;
  }

  .flex-column-100p {
    @extend .flex-column;
    min-width:100%;
    min-height:100%;
  }
  .flex-column-100p-align-start {
    @extend .flex-column ;
    justify-content: flex-start;
    width:100%;
    height: 600px;
  }

  .flex-column-90p {
    @extend .flex-column;
    width:100%;
    height:90%;
  }

  .flex-grow-1 {
    flex-grow: 1;
  }


  .width-100p {
    width:100%;
  }
  .width-50p {
    width:50%;
  }
  .width-30p {
    width:30%;
  }

  .height-100p {
    height:100%;
  }
  .max-dim {
    width:100%;
    height:100%;
  }
  .height-100px {
    height:100px;
  }
  .height-80p {
    height: 80%;
  }
  .height-10p {
    height:10%;
  }
  .height-90p {
    height:90%;
  }

  .height-30p {
    height:30%;
  }
  .column-reversed {
    display:flex;
    flex-direction: column-reverse;
  }
  .font15 {
    font-size:15px;
  }
  .betting-font {
    font-size: 20px;
    @include media('>=phone', '<tablet') {
         font-size: 18px;
    }
  }
  .font12 {
    font-size:12px !important;
  }
  .flex-row-stretch {
    display: flex;
    flex-direction: row;
    align-items: stretch;
  }


  .flex-row-stretch-wrap {
    @extend .flex-row-stretch ;
    flex-wrap: wrap;
  }
  .license-main {
    display: flex;
    flex-direction: column;

    height:100%;
  }
  .while-space {
    white-space: pre;
  }
  .olive-bg {
    z-index: 1;
    background: #6B8E23 !important;
  }
  .report{
    display: flex;
    flex-direction: row-reverse;
    width:100%
  }
  .image {
    margin-right:20px;
    width:100px;
    height: 100px;
  }

  .image-pointer {
     cursor: pointer;
  }
  .cards-border {
    border: solid red;
    border-width:5px;
  }
  .bet-header {
    text-align: center;
  }
  .rich-black-bg {
    color:white;
    background: #010203;
  }
  .inset-border {
    border-style: inset;
    border-width:14px;
    border-color: grey;
    // background: black;
  }
  .inner-inset-border {
    border-style: inset;
    border-width:1px;
  }

  $copper: #c68346;
  $dgold: #9e5724;
  .copper-bg {
    background: $copper !important;
    color: black !important;
  }
  .dark-gold-bg {
    background: $dgold !important;
    color: white !important;
  }
  .dark-green-bg {
    background: green !important;
    color: white !important;
  }

  .inner-insert-5px {
    border-style: inset;
    border-width:5px;
    border-color: grey;

  }

  .gold-font {
    background: black !important;
    color: gold !important;
  }


  .cards-width {
    // max-width:700px;
    // @include media('>=tablet', '<desktop') {
    //   max-width:600px;
    // }
    // @include media('>=phone', '<tablet') {
    //  max-width:100%;
    // }


    min-width:200px;
  }
  .number-border {
    border: groove grey;
    border-width:3px;
  }
  .cards-makeup {
    @extend .cards-bground;
    @extend .cards-width;
    @extend .cards-border;
  }

  .play-cards-makeup {
    margin-top:20px;
    @extend .cards-bground;
    @extend .cards-width;
     min-height:450px;
    @extend .cards-border;
  }
  .cards {
   display: flex;
   flex-direction: row;

  }
  .curvey-border {
    border-radius: 75px 75px !important;
  }

  // .unfair {
  //   background: $my-app-warn !important;
  //   border:2px solid grey;
  //   border-radius: 75px 75px !important;
  //   font-size:25px;
  //   text-align: center;
  //   font-color: black;
  // }

   .rotate90 {
     transform: rotate(90deg)
   }
   .rotate45 {
     transform: rotate(-45deg)
   }
   .rotate15 {
     transform: rotate(15deg)
   }

  // .fair {
  //   background: map_get($my-app-accent, !important;
  //   border:2px solid grey;
  //   border-radius: 75px 75px !important;
  //   font-size:25px;
  //   text-align: center;
  //   font-color: black;
  // }
  .panel-width {
    width:400px !important
  }

  .small-panel-width {
    width:300px !important
  }
  .first-panel {
    margin-top:75px;
    @extend .enter;
    // background: url(paper.gif);
    @extend .curvey-border;
    @extend .panel-width;
  }
  .rest-panel {
    margin-top:15px;
    @extend .leave;
    @extend .curvey-border;
    @extend .panel-width;
  }

  .last-panel {
    margin-top:15px;
    @extend .enter;
    // background: url(paper.gif);
    @extend .curvey-border;
    @extend .panel-width;
  }

  .small-first-panel {
    margin-top:75px;
    @extend .enter;
    // background: url(paper.gif);
    @extend .curvey-border;
    @extend .small-panel-width;
  }
  .small-rest-panel {
    margin-top:15px;
    @extend .leave;
    @extend .curvey-border;
    @extend .small-panel-width;
  }

  .scale-p7 {
   @include media('>=phone', '<tablet') {
     transform: scale(0.7) !important;
   }
  }

  .scale-mat-fab {
    transform: scale(0.8) !important;
    @include media('>=phone', '<tablet') {
         transform: scale(0.6) !important;
    }
  }

  control-buttons-width {
    width:80px !important;
    @include media('>=phone', '<tablet') {
          width:40px !important;
    }
  }

  .shuffle-box {
    position: absolute;
    top:300px;
    left:100px;
  }
  .shuffle-card {
    background-color: transparent;
    font-size:14px;
    z-index:4;
  }

  .border {
    border:0.5rem groove red;
  }
  .border-red{
    border:0.5rem groove red;
  }
  .math-cell-small {
   width:60px;
   height:60px;
   font-size: 15px;
   color: white;
  }
  .background-teal {
    z-index: -2;
    background: #80cbc4 !important;
  }
  .flex {
    display: flex;
  }
  .margin-top-right-6px {
    margin-top: 6px;
  }
  .flex-row-reverse{
    display: flex;
    flex-direction: row-reverse;
  }

  .margin-top-10px {
    margin-top: 10px !important;
  }
  .margin-top-20px {
    margin-top: 20px !important;
  }

  .cards-button-margin {
    margin-right: 5px !important;
    margin-top: 5px !important;
  }

  .level-bottom {
      display: flex;
      flex-direction: column;
      width:100%;
      margin-top: 10px;
    }



  .gold-font {
    color:gold;
  }

  @mixin set_screen_size($width, $height) {
    width: $width;
    height: $height;
  }

  .screen_size {
    @include set_screen_size(320, 568);
  }

  $max_cards_height: 130px;
  $min_cards_height: 82px;



  .flex-column {
    display: flex;
    flex-direction: column
  }

  .flex-column-reverse {
    display: flex;
    flex-direction: column-reverse;
  }


  .test-height {
    min-height:93%;
  }
  .numbers-height {
    min-height:7%;
  }

  .numbers-bg {
	  background: #3f51b5 !important;
	  color: white !important;
  }

  .bottom-bg {
	  background: #1a237e !important;
	  color: white !important;
  }
  .max-height {
	  height:100%;
  }
  .flex-row-center-fake {
    margin-left:30%;
    @include media('>=phone', '<tablet') {
         margin-left:0px;
       }
  }
  .login-width-limit {
    max-width:300;
    @include media('>=phone', '<tablet') {
         max-width: 100%;
       }
  }
  .flex-row-center-1 {
    @extend .flex-row ;
    justify-content: center ;
    @include media('>=phone', '<tablet') {
         justify-content: flex-start;
       }
  }
  .flex-row-top-top {
    @extend .flex-row ;
    height:120px;
    width:100%;
    justify-content: center ;
    @include media('>=phone', '<tablet') {
         justify-content: flex-start;
       }
  }
  .desktop-offset {
    margin-left:30% important;
    @include media('>=phone', '<tablet') {
        margin-left:0px important;
       }
  }
  .flex-row-center {
    @extend .flex-row;
    width:100%;
    height:100%;
    justify-content: center;
    @include media('>=phone', '<tablet') {
         justify-content: flex-start;
       }
  }
  .min-dims {
    min-height:200px;
    min-width:200px;

    @include media('>=phone', '<tablet') {
      min-height:100px;
      min-width:100px;


       }
  }
  .flex-row-space-between {
    @extend .flex-row;
    justify-content: space-between;
  }
   .space-between {
    justify-content:space-between;
  }
  .hide {
    visibility: hidden;
  }
  .show {
      visibility: visible;
  }
  .flex-row-100p {
    @extend .flex-row;
    height:100%;
    width:100%;
    align-items: flex-start;
  }

  .flex-start {
    align-self: flex-start;
  }
  .parent-center {
    justify-content: center;
  }
  .flex-center {
    align-self: flex-center;
  }
  .flex-end {
    align-self: flex-end;
  }

  .very_light_blue {
    background: #006699 !important;
    color:white !important;
  }
  .red-color {
    color: red !important;
  }
  .mobile-border-on {
    z-index:1;
    border:0.2rem dotted #3f51b5;
    @extend .very_light_blue;
    @include media('>=phone', '<tablet') {
         font-size: 20px !important;
       }
  }
  .mobile-border-off {
    @include media('>=phone', '<tablet') {
         font-size: 15px !important;
       }
  }
  .math-card-border-on {
      border:0.2rem dotted red !important;
  }
  .classification-bold-font {
       font-size: 26px !important;

       @include media('>=phone', '<tablet') {
         font-size: 19px !important;
       }

  }
  .math-card-border-off {
  }


  .animate-height-0{
      max-height: 0;
      transition: max-height 0.4s ease-out;
      overflow: hidden;
  }

  .home-image-margin {
    margin-left:200px;
    @include media('>=phone', '<tablet') {
      margin-left:0px !important;
    }
    @include media('>=tablet', '<desktop') {
      margin-left:100px !important;
    }
  }
  .centerit {
    display: flex;
    height:100%;
    width:100%;
    justify-content: center ;
    @include media('>=phone', '<tablet') {
         justify-content: flex-start;
        }
  }
  .flex-row-top-top {
    @extend .flex-row ;
    height:120px;
    width:100%;
    justify-content: center ;
    @include media('>=phone', '<tablet') {
         justify-content: flex-start;
       }
  }
  .top-top-flex-row {
    @extend .flex-row ;
    height:120px;
    font-size:14px;
  }
  .top-center-it {
    display: flex;
    height:120px;
    width:100%;
    justify-content: center ;
    @include media('>=phone', '<tablet') {
         justify-content: flex-start;
        }
  }
  .rule-abbr-font {
      font-size:20px !important;
  }
  .rule-column-30px {
    text-align: center;
  }
  .font-size-14 {
    font-size:14px;
  }

  .red-font {
    background: red;
    z-index:10px;
  }

  .yellow-font {
    background: yellow;
    z-index:10px;
  }
  .rule-cell {
    font-size:14px;
    text-align: center !important;
    color:black;
    z-index:-10px;
  }
  .number-text {
    width:34px;
    height:34px;
    @include media('>=phone', '<tablet') {
      width:20px;
      height:20px;
    }
  }
  .numbers-nav-common {
    border-style:solid !important;
    border-radius:50% !important;
    border-color: white !important;
    // color:white !important;
    text-align: center !important;
  }
  .numbers-nav {
    @extend .primary-bg;
    @extend .number-text;
    @extend   .numbers-nav-common
  }

  .numbers-nav-primary {
    @extend .number-text;
    @extend  .numbers-nav-common;
    @extend .primary-bg;

  }

  .numbers-nav-warn {
    @extend .number-text;
    @extend .numbers-nav-common;
    @extend .warn-bg;

  }

  .numbers-nav-accent {
    @extend .number-text;
    @extend   .numbers-nav-common;
    @extend .accent-bg;

  }

  .play-border {
    border: 3px  black !important;
    border-width:thick !important;
    border-style:solid !important;
    text-align: center !important;
  }
  .player-dims {
    min-width:140px;
    min-height:35px;
  }
  .dealer-label {
    // display:flex;
    @extend .number-text;
    // @extend .accent-bg;
    @extend .player-dims;
    // @extend .play-border;
  }

  .player-label {
    @extend .number-text;
    // @extend .warn-bg;
    @extend .player-dims;
    // @extend .play-border;
    color: white;

  }

  .player-label-none {
    @extend .number-text;
    @extend .player-dims;
    color:white;
  }

  .numbers-nav-reduced {
    @extend .warn-bg;
    color: black !important;
    @extend .cardcount-font;
    border: 3px dotted white !important;
    width:34px;
    height:34px;
    border-radius:30px !important;
    text-align: center;
  }

  .numbers-nav-reduced-mobile {
    @extend .warn-bg;
    color: black !important;

    @extend .cardcount-font;
    border: 3px dotted white !important;
    width:34px;
    height:34px;
    border-radius:30px !important;

    text-align: center;
  }
  .enter {
     // background-color: #556b2f !important;
     background-color: #2F4F4F !important;
     color:white !important;
  }
  .leave {
    background-color: #A9A9A9 !important;
    color:black !important;
  }

  .mild-black {
    background: #383838 !important;
    color: white !important;
  }
  .mild-while {
    background: white !important;
    color: black !important;
  }

  .leave-white {
    background-color: #A9A9A9 !important;
    color:white !important;
  }

  .margin-left-20 {
    margin-left:20px;
  }

  .margin-left-0 {
    margin-left:0px;
  }

  .thumbs-dims {
    width:70px;
    height:40px;
  }

  .whiteout {
    background: white !important;
    color:white !important;
  }

  @mixin set_display_dim ($width, $min_height) {
    width: $width;
    min-height: $min_height;
  }

  @mixin square($dim) {
    min-width:$dim;
    min-height:$dim;
  }
  .square80 {
    @include square(80);
  }
  .square100 {
    @include square(100);
  }
  .rotate-square80 {
    position: relative;
    @extend .square80;
  }
  .imargins {
    margin-right:5px;
  }

  rules-times-table {
    // margin-right:15px;
    text-align: center;
  }

  body{
      overflow-x:hidden;
  }
  .thumsup-animation {
    animation:  rotate 3s cubic-bezier(0.4,0.0,0.2,1)  !important;
  }

  .rotate {
    transform:  rotate(-0.25turn);
    transform-origin: top left;
  }

  .mat-tab-label {
    min-width: 25px !important; // In addition to mat-strech-tabs allowing tabs to fill remaining space in parent container
    padding: 10px;
  }

  @keyframes rotate {
    from {
    transform: rotate(0deg);
    }

    25% {
       transform:  rotate(-0.25turn);
       transform-origin: top left;
    }

    to {
     transform:  rotate(0deg);
     transform-origin: top left;

    }
  }
  .rule-scene {
      display: block;
      height: 350px;
      width:  350px;
      perspective: 500px;
  }
  .rule-scene .flip {

      height: 100%;
      width:  100%;
      position: relative;
      transform-style: preserve-3d;
  }
  .flip .face {
      display: block;
      position: absolute;
      width: 100%;
      height: 100%;
      backface-visibility: hidden;
  }

  .face .front {
      transform: rotateY(0deg);
  }
  .face .back {
      transform: rotateY(-180deg);
  }
